.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    padding: 20px 0;
}

.pagination-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.pagination-button:hover:not(:disabled) {
    background-color: #f0f0f0;
}

.pagination-button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}