.research-publications {
  /* padding: 20px; */
}

.publications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.publication-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.publication-card h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.authors {
  color: #666;
  font-style: italic;
}

.journal {
  color: #444;
  margin: 10px 0;
}

.abstract {
  font-size: 0.9rem;
  margin-top: 10px;
  line-height: 1.4;
}

.error-message {
  color: #ff0000;
  padding: 20px;
  text-align: center;
}

.publication-image {
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 4px;
}

.publication-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.type {
    color: #666;
    font-size: 0.9rem;
    margin: 8px 0;
    text-transform: uppercase;
}

.publication-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.publication-link:hover {
    background-color: #0056b3;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    padding: 20px 0;
}

.pagination-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.pagination-button:hover:not(:disabled) {
    background-color: #f0f0f0;
}

.pagination-button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
} 