.blog-wrapper {
    .text-date {
        color: #979797;
        font-size: 18px;
        margin-bottom: 2rem;
        display: inline-block;
    }

    p {
        font-size: 18px;
        line-height: 194.6%;
        color: #3A5279;
    }
}

.alternate-blog {
    .indicator {

        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #969696;
    }
    h4{
        cursor: pointer;
    }
}

.blog-page {
    min-height: 100vh;
}